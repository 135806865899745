import React from 'react';
import Announcement from 'components/Announcement';
import './MainPage.scss';
import ActionCard from 'components/elections/ActionCard';
import { useNavigate } from 'react-router-dom';
import { useAppDispatch, useAppSelector } from 'store/store';
import { QuestionProps } from 'components/ui/Question/Question';
import QuestionBlock from 'containers/QuestionBlock';
import { asyncFetchElections } from 'store/reducers/elections/thunks';
import { IElection } from 'store/reducers/elections/types';
import Chip from 'components/ui/Chip';
import dayjs from 'dayjs';
import { ReactComponent as AlertIcon } from 'assets/icons/mingcute_alert-octagon-fill.svg';
import { ReactComponent as CalendarIcon } from 'assets/icons/mingcute_calendar-fill.svg';
import { ReactComponent as ClockIcon } from 'assets/icons/mingcute_time-fill.svg';
import { SwitchValueProps } from 'components/ui/Switch/Switch';
import Switch from 'components/ui/Switch';
import { isStudent } from 'js/utils';

const questions: QuestionProps[] = [
    {
        header: 'Я столкнулся с проблемой, кому писать?',
        text: (
            <>
                Если вы столкнулись с проблемой в работе системы online-выборов,
                мы постараемся оперативно её решить!
                <br />
                Просто напишите в{' '}
                <a
                    href="https://t.me/dobroplat"
                    target="_blank"
                    rel="noreferrer"
                >
                    Telegram
                </a>{' '}
                или{' '}
                <a
                    href="https://vk.com/vladhasnoid"
                    target="_blank"
                    rel="noreferrer"
                >
                    VK
                </a>
            </>
        ),
    },
    {
        header: 'Где можно посмотреть статистику голосования?',
        text: (
            <>
                Статистику голосования в реальном времени можно узнать в
                аудитории Студенческого совета СПбГУТ 119/1 с 9:00 до 18:00.
            </>
        ),
    },
    {
        header: 'Когда будут оглашены результаты выборов?',
        text: (
            <>
                Голосование завершится 16 декабря в 18:00 по Московскому
                времени. Поскольку распределение голосов подсчитывается системой
                в реальном времени, результаты выборов будут объявлены сразу же
                после завершения голосования и будут опубликованы на главной
                странице этого сайта, а также в группе{' '}
                <a
                    href="https://vk.com/studsovet.bonch"
                    target="_blank"
                    rel="noreferrer"
                >
                    Студенческого совета СПбГУТ
                </a>{' '}
                и{' '}
                <a href="https://vk.com/ingut" target="_blank" rel="noreferrer">
                    InGut
                </a>
                .
            </>
        ),
    },
    {
        header: 'Будет ли очное оглашение результатов выборов?',
        text: (
            <>
                Несомненно. Для всех желающих в 18:00 в аудитории 119/1 в
                реальном времени будут оглашены результаты выборов и объявлен
                победитель.
            </>
        ),
    },
];

const switchValues: SwitchValueProps[] = [
    {
        key: 'personal',
        name: 'Мои',
    },
    {
        key: 'all',
        name: 'Все',
    },
];

function ElectionChips({
    is_active,
    date_end,
    date_start,
}: IElection): React.ReactElement[] {
    return [
        <Chip key={1} icon={<AlertIcon />}>
            Голосование {!is_active && 'еще не '}
            началось
        </Chip>,
        <Chip key={2} icon={<CalendarIcon />}>
            {dayjs(date_start).format('DD MMMM')}
        </Chip>,
        <Chip key={3} icon={<ClockIcon />}>
            с {dayjs(date_start).format('HH:mm ')}
            до {dayjs(date_end).format('HH:mm')}
        </Chip>,
    ];
}

function MainPage(): React.ReactElement {
    const navigate = useNavigate();
    const dispatch = useAppDispatch();

    const { registrationStatus } = useAppSelector(
        state => state.electionsStore
    );

    React.useEffect(() => {
        dispatch(asyncFetchElections());
    }, [dispatch]);

    const { elections } = useAppSelector(state => state.electionsStore);

    const { user } = useAppSelector(state => state.userStore);

    const [buttonText, buttonDisabled] = ['Заполнить анкету', false];

    const [switchValue, setSwitchValue] = React.useState(switchValues[0].key);

    return (
        <>
            {!registrationStatus && (
                <Announcement
                    onClick={() => navigate('/candidates')}
                    buttonText={buttonText}
                    buttonDisabled={buttonDisabled}
                >
                    Красивый заголовок для привлечения внимания <br />
                    кандидатов
                </Announcement>
            )}
            <div className="elections-wrapper">
                <h3>Доступные выборы</h3>
                {!!user && (
                    <Switch
                        value={switchValue}
                        values={switchValues}
                        onChange={value => setSwitchValue(value)}
                        maxWidth="100%"
                    />
                )}
                <div className="elections-grid">
                    {elections
                        .filter(election => {
                            if (!user || switchValue === 'all') return true;
                            if (isStudent(user)) {
                                return (
                                    !election.faculty ||
                                    election.faculty?.id ===
                                        user.group.faculty.id
                                );
                            }
                            return true;
                        })
                        .map(election => (
                            <ActionCard
                                onClick={() =>
                                    navigate(`/elections/${election.id}`)
                                }
                                actionText="Перейти к выборам"
                                key={election.id}
                                chips={ElectionChips(election)}
                                type={election.faculty?.name}
                                subHeader={`Студенческого совета ${
                                    election.faculty?.name || 'СПбГУТ'
                                }`}
                                showIcon
                                chipsGrid
                                headerWidth="70%"
                            >
                                {election.name}
                            </ActionCard>
                        ))}
                </div>
            </div>
            <div>
                <h3>Вопросы и ответы</h3>
                <QuestionBlock questions={questions} />
            </div>
        </>
    );
}

export default React.memo(MainPage);
